:root {
  --navbar-height: 1px;
  --bottom-nav-height: 1px;
  --toastify-toast-min-height: 20px;
  --text-color: #ffffff;
  --selected-text-color: #8ebbff;
  --navbar-background: #2A3A5A;
  --bottom-nav-background: rgb(35, 35, 35, 0.95);
  --main-content-body-background: #ffffff;
}

.App {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  color: white;

  touch-action: none;
  overflow: hidden;
  overscroll-behavior: none;
}

.AppContainer {
  position: relative;
  height: 100vh;

  touch-action: none;
  overflow: hidden;
  overscroll-behavior: none;
}

.PageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  position: fixed;
  top: var(--navbar-height);
  bottom: var(--bottom-nav-height);
  width: 100vw;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 1rem 2rem 1rem;
  background-color: var(--main-content-body-background);
}

.ArrayContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  width: 100%;
  box-shadow:
      rgba(0, 0, 0, 0.16) 0px 3px 6px,
      rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
